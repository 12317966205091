import React from 'react';
import { DynamicColorElement, Navbar } from './components';

import '../src/assets/scss/bootstrap/bootstrap.scss';
import '../src/assets/scss/custom/custom.scss';
import { cvData, sideBanners } from './utils/dataObjects';
// import { randomColor } from './utils/utils';

function App() {
   // const color = randomColor();
   const color = 'rgb(37, 99, 235)';

   return (
      <>
         <div id={'topHeaderWithImage'} className="container-fluid g-0">
            <Navbar logoColor={color} />

            <section className="container kk-main-hp-text-container">
               <div className="row justify-content-center">
                  <div className="col-11 col-md-10 align-items-center position-relative">
                     <div className={'kk-title-hello'}>Hello, I'm</div>
                     <h1 className={'kk-title-name'} style={{ marginLeft: -8, color: color }}>
                        KIRILL
                     </h1>
                     <h1 className={'kk-title-second-name mt-4'} style={{ color: color }}>
                        TER
                     </h1>
                     <div className={'kk-title-pro-dev'}>
                        TEAM{'\u00A0'}LEAD
                        <br />
                        <span className="ps-1 ps-sm-2">JS{'\u00A0'}DEVELOPER</span>
                     </div>
                  </div>
               </div>
            </section>
         </div>

         <div className="container-fluid kk-main-hp-about-container">
            {/*Banners*/}
            <div className={'kk-banners-container d-none d-lg-block'}>
               {sideBanners.map(item => {
                  return (
                     <div key={item.id} className="kk-banners-item-holder">
                        <div className={'col d-flex justify-content-end mb-3'}>
                           <div
                              className={'kk-side-banners-line'}
                              // style={{ backgroundColor: color }}
                           />
                        </div>
                        <div className={'col '}>
                           <p style={{ color: color }} className={'kk-side-banners-text'}>
                              {item.content}
                           </p>
                        </div>
                     </div>
                  );
               })}
            </div>

            {/*BG Lines*/}
            <div className={'kk-bg-lines-holder d-none d-md-block'}>
               <DynamicColorElement elName={'bgLines'} fill={color} />
            </div>

            <section className={'container'}>
               <div id={'aboutMe'} className="row position-relative">
                  <div className="col-sm-12 col-lg-8 kk-gutter-before-about">
                     <span className={'kk-title-about kk-text-shadow'} style={{ color: color }}>
                        About
                     </span>
                     {'\u00A0'}
                     <span className={'kk-title-me'}>Me</span>
                     <p className={'kk-text-shadow mt-5'}>
                        Hi, my&nbsp;name is&nbsp;Kirill Ter, and I&nbsp;am an&nbsp;experienced Team
                        Lead and developer with over 10&nbsp;years of&nbsp;expertise
                        in&nbsp;designing and building websites, desktop applications, and mobile
                        applications.
                     </p>
                     <p className={'kk-text-shadow'}>
                        With a&nbsp;strong technical background and leadership skills, I&nbsp;have
                        successfully managed development teams, ensuring efficient workflows,
                        high-quality code, and timely project deliveries. My&nbsp;ability
                        to&nbsp;mentor and guide developers fosters a&nbsp;productive and innovative
                        work environment.
                     </p>
                     <p className={'kk-text-shadow'}>
                        Being an&nbsp;expert in&nbsp;UI/UX design, I&nbsp;guarantee that every
                        product&nbsp;I work on&nbsp;is&nbsp;not only functional but also visually
                        compelling and user-friendly. My&nbsp;combined expertise in&nbsp;development
                        and design allows me&nbsp;to&nbsp;bridge the gap between technical
                        implementation and outstanding user experience.
                     </p>
                     <p className={'kk-text-shadow'}>
                        As&nbsp;a&nbsp;leader and developer, I&nbsp;am committed to&nbsp;delivering
                        high-quality solutions with professionalism, innovation, and a&nbsp;focus
                        on&nbsp;creating unique, impactful products.
                     </p>
                     <h4 className={'kk-text-shadow mt-5'}>Contacts</h4>
                     <p>
                        Email:{'\u00A0'}
                        <a
                           className={'text-decoration-underline'}
                           href={'mailto:kirill.ter@gmail.com'}>
                           kirill.ter@gmail.com
                        </a>
                     </p>
                  </div>

                  <div
                     className="g-0 col-4 d-none d-xl-block kk-gutter-before-about position-relative"
                     style={{ zIndex: -2, top: -50 }}>
                     <img alt={'Kir Ter TED'} src={'assets/images/kirter_ted.png'} />
                  </div>
               </div>
            </section>

            <section className={'container'} style={{ marginBottom: 160 }}>
               <div id={'curriculumVitae'} className="row justify-content-center">
                  <div style={{ marginTop: 280 }} className="col-11 col-sm-10">
                     <div style={{ color: color }} className={'kk-title-about'}>
                        Curriculum <span className={'kk-title-me'}>Vitae</span>
                     </div>
                  </div>
               </div>

               <div className="row justify-content-center mt-3">
                  <div className={'col-11 col-sm-10 ps-3'}>
                     <a
                        className={'d-flex'}
                        target={'_blank'}
                        style={{
                           fontSize: 18,
                           color: 'gray',
                           textDecoration: 'underline',
                           marginInlineEnd: 12,
                        }}
                        href="./assets/cv/CV_Kirill_Ter.pdf">
                        Download CV PDF
                        <div
                           style={{
                              width: 20,
                              height: 20,
                              marginInlineStart: 8,
                              lineHeight: 1.3,
                           }}>
                           <DynamicColorElement elName={'iconDownload'} fill={color} />
                        </div>
                     </a>
                  </div>
               </div>

               <div className="row kk-cv-container justify-content-sm-center justify-content-lg-start">
                  <div className="col-12 col-sm-11">
                     {cvData.map(item => {
                        return (
                           <div
                              className="row justify-content-center"
                              key={item.id}
                              style={{ marginTop: 30 }}>
                              <div className="kk-cv-logos col-12 col-md-6 text-start text-md-end mb-5 mb-md-0">
                                 <img
                                    src={item.img.src}
                                    alt={item.img.alt}
                                    width={item.img.width}
                                    height={item.img.height}
                                    className="d-inline-block align-text-top"
                                 />
                              </div>
                              <div className="col-11 col-md-6">
                                 <p>{item.content}</p>
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </section>
         </div>

         <div id={'footer'} className="container-fluid" style={{ marginTop: 100 }}>
            <div className={'row justify-content-center'}>
               <div className={'col-auto g-0'}>
                  <div
                     className={'kk-side-banners-line'}
                     style={{ width: 72, height: 4, backgroundColor: color, marginTop: 12 }}
                  />
               </div>
               <div className="col-auto">
                  <div className={'kk-footer-end-page-text'}>END OF THE PAGE</div>
               </div>
            </div>
         </div>
      </>
   );
}

export default App;
